import React from 'react'
import QuoteEmail from '../../components/QuoteEmail'
import Layout from '../../components/Global/Layout'
import Loader from '../../components/Global/Loader'
import { useTrademarkConfigurator } from '../../hooks/useTrademarkConfigurator'

const locale = 'en-CA'

const QuoteEmailPage = () => {
  var configurator = useTrademarkConfigurator({ locale: locale })

  if (!locale) {
    return (
      <Layout url='/quote-email' locale={locale}>
        <Loader loading />
      </Layout>
    )
  }

  return (
    <Layout locale={locale} url='/quote-email' title='Quote Email'>
      <QuoteEmail locale={locale} configurator={configurator} />
    </Layout>
  )
}

export default QuoteEmailPage
